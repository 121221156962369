<template>
  <div class="auth-wrapper auth-v2">
    <b-row class="auth-inner m-0">
      <!-- Left Text-->
      <b-col lg="8" class="d-none d-lg-flex align-items-center p-5">
        <div class="w-100 d-lg-flex align-items-center justify-content-center px-5">
          <b-img fluid :src="imgUrl" alt="Login V2" />
        </div>
      </b-col>
      <!-- /Left Text-->

      <!-- Login-->
      <b-col lg="4" class="d-flex align-items-center auth-bg px-2 p-lg-5">
        <b-col sm="8" md="6" lg="12" class="px-xl-2 mx-auto">
          <div class="w-100 d-lg-flex align-items-center justify-content-center pb-2 text-center">
            <b-img fluid src="@/assets/images/logo/horus-icon.png" alt="Login V2" />
          </div>
          <b-card-title class="mb-1 font-weight-bold text-center pb-3" title-tag="h2">
            Welcome to {{ $t('App Name') }}!
            <div v-if="stage !== 'production'">
              <i style="font-size: 12px">{{ $t('This is Development Version') }}</i>
            </div>
          </b-card-title>

          <!-- form -->
          <validation-observer ref="loginForm">
            <b-form class="auth-login-form mt-2" @submit.prevent="login">
              <!-- email -->
              <b-form-group>
                <validation-provider
                  #default="{ errors }"
                  name="Username"
                  vid="username"
                  rules="required"
                >
                  <b-form-input
                    id="login-username"
                    v-model="userEmail"
                    :state="errors.length > 0 ? false : null"
                    name="login-username"
                    :placeholder="$t('Username')"
                  />
                  <small class="text-danger">{{ errors[0] }}</small>
                </validation-provider>
              </b-form-group>

              <!-- forgot password -->
              <b-form-group>
                <validation-provider
                  #default="{ errors }"
                  name="Password"
                  vid="password"
                  rules="required"
                >
                  <b-input-group
                    class="input-group-merge"
                    :class="errors.length > 0 ? 'is-invalid' : null"
                  >
                    <b-form-input
                      id="login-password"
                      v-model="password"
                      :state="errors.length > 0 ? false : null"
                      class="form-control-merge"
                      :type="passwordFieldType"
                      name="login-password"
                      :placeholder="$t('Password')"
                    />
                    <b-input-group-append is-text>
                      <feather-icon
                        class="cursor-pointer"
                        :icon="passwordToggleIcon"
                        @click="togglePasswordVisibility"
                      />
                    </b-input-group-append>
                  </b-input-group>
                  <small class="text-danger">{{ errors[0] }}</small>
                </validation-provider>
              </b-form-group>

              <!-- submit buttons -->
              <b-overlay :show="loading" block opacity="0.6" spinner-variant="primary">
                <b-button type="submit" variant="primary" block> {{ $t('Sign in') }} </b-button>
              </b-overlay>
            </b-form>
          </validation-observer>
        </b-col>
      </b-col>
      <!-- /Login-->
    </b-row>
  </div>
</template>

<script>
/* eslint-disable global-require */
import { ValidationProvider, ValidationObserver } from 'vee-validate';
import AppLogo from '@core/layouts/components/Logo.vue';
import {
  BRow,
  BCol,
  BLink,
  BFormGroup,
  BFormInput,
  BInputGroupAppend,
  BInputGroup,
  BFormCheckbox,
  BCardText,
  BCardTitle,
  BImg,
  BForm,
  BButton,
  BOverlay,
  VBTooltip,
} from 'bootstrap-vue';
import useJwt from '@/auth/jwt/useJwt';
import { required, email } from '@validations';
import { togglePasswordVisibility } from '@core/mixins/ui/forms';
import store from '@/store';
// import { getHomeRouteForLoggedInUser } from '@/auth/utils';

import ToastificationContent from '@core/components/toastification/ToastificationContent.vue';
import constRouter from '@/constants/constRouter';
import browserSignature from 'browser-signature';
import { handleUserProfile } from '@/@core/utils/utils';

export default {
  directives: {
    'b-tooltip': VBTooltip,
  },
  components: {
    BRow,
    BCol,
    BLink,
    BFormGroup,
    BFormInput,
    BInputGroupAppend,
    BInputGroup,
    BFormCheckbox,
    BCardText,
    BCardTitle,
    BImg,
    BForm,
    BButton,
    BOverlay,
    AppLogo,
    ValidationProvider,
    ValidationObserver,
  },
  mixins: [togglePasswordVisibility],
  data() {
    return {
      status: '',
      password: '',
      userEmail: '',
      loading: false,
      sideImg: require('@/assets/images/pages/login-v2.svg'),

      // validation rules
      required,
      email,
    };
  },
  computed: {
    stage() {
      return process.env.VUE_APP_STAGE;
    },
    passwordToggleIcon() {
      return this.passwordFieldType === 'password' ? 'EyeIcon' : 'EyeOffIcon';
    },
    imgUrl() {
      if (store.state.appConfig.layout.skin === 'dark') {
        // eslint-disable-next-line vue/no-side-effects-in-computed-properties
        this.sideImg = require('@/assets/images/pages/login-v2-dark.svg');
        return this.sideImg;
      }
      return this.sideImg;
    },
  },
  methods: {
    login() {
      this.$refs.loginForm.validate().then((success) => {
        if (success) {
          this.loading = true;
          const signature = browserSignature();

          useJwt
            .login({
              username: this.userEmail,
              password: this.password,
            })
            .then((response) => {
              useJwt.setToken(response.data.data?.accessToken);
              useJwt.setRefreshToken(response.data.data?.refreshToken);
              useJwt.profile().then((res) => {
                handleUserProfile(res.data.data);
                this.loading = false;
                this.$router.replace({ name: constRouter.DASHBOARD.name });
              });
            })
            .catch((error) => {
              this.loading = false;
              if (error.response?.status === 422) {
                if (error.response?.data?.errors) {
                  this.$refs.loginForm.setErrors(error.response.data.errors);
                } else {
                  this.$refs.loginForm.setErrors({
                    username: error.response.data.message,
                  });
                }
              } else {
                console.log('err else');
              }
            });
        }
      });
    },
  },
};
</script>

<style lang="scss">
@import '@core/scss/vue/pages/page-auth.scss';
</style>
